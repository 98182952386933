<template>
    <div class="container">
        <div class="create-title">
            <div>设置轮播图</div>
            <div class="btn">
                <el-button @click="routeChange(1)">取消</el-button>
                <el-button type="primary" @click="handleConfirm">保存</el-button>
            </div>
        </div>
        <div class="create-container">
            <colorful-title title="基本信息" class="pd-custom" />
            <div class="form-item">
                <div class="key">名称</div>
                <el-input class="value" v-model="editForm.name" placeholder="请输入轮播图名称"></el-input>
            </div>
            <div class="form-item">
                <div class="key">内容</div>
                <el-input class="value" v-model="editForm.content" placeholder="请输入内容"></el-input>
            </div>
            <div class="form-item">
                <div class="key">位置</div>
                <el-radio-group v-model="radioList" class="value">
                    <el-radio :label="10">小程序</el-radio>
                </el-radio-group>
            </div>
            <div class="form-item">
                <div class="key">跳转类型</div>
                <el-radio-group v-model="redirectType" class="value">
                    <el-radio :label="1">内页</el-radio>
                    <el-radio :label="2">链接</el-radio>
                </el-radio-group>
            </div>
            <div class="form-item mb-20 my-upload">
                <div class="key">轮播图</div>
                <el-upload class="upload-demo" :action="uploadUrl" :show-file-list="false"
                    :before-upload="beforeAvatarUpload" :http-request="handleUpload">
                    <div class="row">
                        <div>
                            <img v-if="imageUrl" :src="imgApi + imageUrl" class="avatar" />
                            <img v-else src="@/assets/images/default.png" alt="" class="size">
                        </div>
                        <div class="flex-start">
                            <el-button size="small" class="width">点击上传</el-button>
                            <div slot="tip" class="el-upload__tip">
                                建议尺寸372px * 288px 或4:3，JPG、PNG格式，图片小于5M
                            </div>
                        </div>
                    </div>
                </el-upload>
            </div>
        </div>
    </div>
</template>

<script>
import Core from '@/core';
export default {
    components: {
        ColorfulTitle: () => import('@/components/common/ColorfulTitle')
    },
    data() {
        return {
            uploadUrl: Core.Const.NET.FILE_UPLOAD_END_POINT,
            imgApi: Core.Const.NET.FILE_URL,
            imageUrl: '',
            editForm: {
                id: '',
                name: '',
                content: ''
            },
            radioList: [],
            redirectType: [],
            detail: ''
        };
    },
    watch: {},
    created() {
        this.editForm.id = this.$route.query.id;
    },
    computed: {},
    mounted() {
        if (this.editForm.id) {
            this.getDetail();
        }
    },
    methods: {
        getDetail() {
            Core.Api.Banner.detail(this.editForm.id).then(
                (res) => {
                    this.editForm.name = res.detail.title;
                    this.editForm.content = res.detail.content;
                    this.detail = res.detail;
                    this.radioList = res.detail.position;
                    this.redirectType = res.detail.redirect_type;
                    this.imageUrl = res.detail.image;
                }
            );
        },
        beforeAvatarUpload(file) {
            const isJPG =
                file.type === 'image/jpeg' || file.type === 'image/png';
            const isLt5M = file.size / 1024 / 1024 < 5;

            if (!isJPG) {
                this.$message.error('上传图片只能是 JPG、PNG 格式!');
            }
            if (!isLt5M) {
                this.$message.error('上传图片大小不能超过 5MB!');
            }
            // let valWidthAndHeight = true;
            // if (this.validWidthDetail && this.validHeightDetail) {
            //     return this.valWidthAndHeight(file);
            // }
            return isJPG && isLt5M;
        },
        // 限制图片尺寸
        // valWidthAndHeight(file) {
        //     return new Promise(function (resolve, reject) {
        //         let width = 750; //图片宽度
        //         let height = 560; //图片高度
        //         let _URL = window.URL || window.webkitURL;
        //         let image = new Image();
        //         image.src = _URL.createObjectURL(file);
        //         image.onload = function () {
        //             let valid = image.width == width && image.height == height;
        //             valid ? resolve() : reject();
        //         };
        //     }).then(
        //         () => {
        //             return file;
        //         },
        //         () => {
        //             this.$message.error(
        //                 '上传图片尺寸不符合,只能是' +
        //                     validWidth +
        //                     'px*' +
        //                     validHeight +
        //                     'px!'
        //             );
        //             return Promise.reject();
        //         }
        //     );
        // },
        async handleUpload(e) {
            console.log('e', e);
            let fileKey = await this.uploadFile(e)
            this.imageUrl = fileKey
            console.log('fileKey', fileKey)
        },
        uploadFile(e) {
            return new Promise(async (resolve, reject) => {
                let file = e.file
                let { host, key, access_id, signature, policy, expire } = await this.getFormDataParams(e.file.type, e.file.name)
                Core.Api.upload({
                    action: host,
                    data: {
                        key,
                        policy,
                        signature,
                        OSSAccessKeyId: access_id,
                    },
                    name: "file",
                    filename:  e.file.name,
                    file: file,
                    onProgress: function(e) {
                        console.log("[upload-clip-image] progress", e.percent);
                    },
                    onSuccess: function(result) {
                        console.log("[upload-clip-image] success", result);
                        // file.file_name = result.data.name;
                        // that.commit("saveTaskAttachment", file);
                        resolve(key)
                    },
                    onError: function(e) {
                        console.log("[upload-clip-image] error", e);
                        reject(e)
                    }
                });
            })
        },
        getFormDataParams(type, fileName) {
            return new Promise((resolve, reject) => {
                Core.Api.UploadFile.uploadFile(
                    type,
                    fileName
                ).then((res) => {
                    resolve(res)
                })
            })
        },
        routeChange(type) {
            switch (type) {
                case 1:
                    this.$router.go(-1);
                    break;
            }
        },
        handleConfirm() {
            console.log('editForm:', this.editForm);
            if (!this.editForm.name) {
                return this.$message.warning('请输入轮播图名称');
            }
            Core.Api.Banner.save(
                this.editForm.id,
                this.imageUrl,
                this.radioList,
                this.redirectType,
                this.editForm.name,
                this.editForm.content,
                0,
                0
            ).then((res) => {
                this.$message.success('保存成功');
                this.routeChange(1);
            });
        }
    }
};
</script>
<style lang="scss" scoped>
.container {
    padding: 0 30px 40px;
    .create-title {
        height: 62px;
        line-height: 62px;
        font-size: 20px;
        font-weight: 500;
        color: #323538;
        display: flex;
        justify-content: space-between;
    }
    .create-container {
        width: 100%;
        background: #ffffff;
        border-radius: 6px;
        border: 1px solid #e1e7ec;
        overflow: hidden;
        .pd-custom {
            padding: 30px 60px;
        }
        .form-item {
            display: flex;
            align-items: center;
            width: 100%;
            min-height: 32px;
            box-sizing: border-box;
            padding: 0 70px;

            + .form-item {
                margin-top: 16px;
            }

            .key {
                width: 72px;
            }
            .value {
                width: 500px;
                margin-left: 16px;
            }
            &.mb-20 {
                margin-bottom: 50px;
            }
            .upload-demo {
                margin-left: 14px;
                .row {
                    display: flex;
                    flex-direction: row;
                    align-items: flex-start;
                    .flex-start {
                        display: flex;
                        flex-direction: column;
                        .width {
                            width: 86px;
                        }
                    }
                    .size {
                        width: 200px;
                        height: 112px;
                        margin-right: 20px;
                    }
                }
            }
        }
        .set-item {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0 70px 60px 70px;
        }
        .custom-add {
            display: flex;
            flex-direction: column;
            .row {
                display: flex;
                flex-direction: row;
            }
            .custom-item {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 0 20px 20px 70px;
                width: 582px;
                .check {
                    font-size: 12px;
                    font-weight: 400;
                    color: #323538;
                }
                .key {
                    font-size: 12px;
                    font-weight: 400;
                    color: #323538;
                    width: 160px;
                }
                .value {
                    width: 280px;
                    background: #f6f7fa;
                    font-size: 12px;
                    font-weight: 400;
                    color: #323538;
                }
            }
        }
        .add-field {
            margin: 10px 70px 30px 70px;
            width: 582px;
            border: 1px dashed #d9d9d9;
        }
    }
}
</style>

<style lang="scss">
.create-container {
    .set-item {
        .el-select .el-input {
            font-size: 12px;
        }
    }
}
</style>
